<template>
  <span class="rule-view"
  ><strong>Rule</strong> :<br/>
    <span class="-type">{{ deCamel(rule.type) }}</span> <span
        v-if="rule.rule.closed_reason">and set reason to <span class="-closed-reason">{{
        rule.rule.closed_reason
      }}</span></span> when <span
        class="-rule">{{ Object.keys(rule.rule)[0] }}</span> set to
    <span class="-status">{{ rule.rule.status }}</span> for more than <span class="-after">{{ rule.rule.after }}</span> days
  </span>
</template>
<script>
export default {
  name: "RuleDisplay",
  props: {
    rule: {
      type: Object,
      default: () => ({})
    },
  },
  data: () => ({})
}
</script>
<style lang="scss">
span.rule-view {
  font-weight: bold;
  font-size: 14px !important;
  //color: grey;

  span {
    &.-rule,
    &.-type,
    &.-status,
    &.-closed-reason,
    &.-after {
      font-size: 18px !important;
      color: #ff7a59;
    }
  }
}
</style>