<template>
  <Form ref="conditionForm" :validation-schema="schema" class="w-100 text-left" @submit="saveForm">
    <span class="d-flex justify-content-end link-text" @click="handleClearForm">clear</span>
    <SelectInput
        :options="dropdownOptions"
        label="Rule type"
        name="type"
        @selected="handleTypeSelection"
    />
    <div v-if="ruleType === 'close-trace'">
      <SelectInput
          :options="getStatusTPValues"
          label="Status ( the trace must be in this status to qualify )"
          name="status"
          @selected="handleStatusSelected"
      />
      <SelectInput
          v-if="showClosedReason"
          :options="getStatusClosedReason"
          label="Closed reason ( close the trace with this reason )"
          name="closed_reason"
      />
      <TextInput
          label="Period ( the number of days the trace is in the status above before closing )"
          name="after"
          placeholder="3"
          type="text"
      />
    </div>
    <button class="fabric-btn fabric-btn-submit">Create</button>
  </Form>
</template>
<script>
import {mapGetters, mapActions, mapMutations} from "vuex";
import * as Yup from "yup";

export default {
  name: "RagRuleForm",
  data: () => ({
    parent: 'trace',
    range: 1,
    emailTemplate: false,
    showClosedReason: false,
    ruleType: null
  }),
  setup() {
    const schema = Yup.object().shape({
      type: Yup.string().label('Rule type').required().nullable(),
      status: Yup.string().label('Status ( the trace must be in this status to qualify )').required().nullable(),
      // period: Yup.string().label('Period ( the number of days the trace is in the status above before closing )').required().nullable()
    });
    return {
      schema
    };
  },
  components: {},
  computed: {
    ...mapGetters([
      "getStatusTPValues",
      "getStatusClosedReason",
    ]),
    dropdownOptions() {
      return [
        {
          id: "close-trace",
          text: "Close trace after (n) days..."
        },
      ]
    },
  },
  methods: {
    ...mapMutations([
      "setNotification"
    ]),
    ...mapActions([
      "saveTraceRule",
      "saveTraceRule",
    ]),
    handleStatusSelected(value) {
      switch (value) {
        case 'complete':
        case 'PCS Closed':
          this.showClosedReason = false;
          break;
        default:
          this.showClosedReason = true;
      }
    },
    handleTypeSelection(value) {
      if (!value) return;
      this.ruleType = value;
    },
    handleClearForm() {
      this.$refs.conditionForm.resetForm();
    },
    async saveForm({type, status, closed_reason = null, after}) {
      let params = {
        ...{parent: this.parent},
        type,
        rule: {
          status,
          closed_reason,
          after
        }
      }

      let response = await this.saveTraceRule(params).catch(err => {
        this.setNotification({
          show: true,
          type: "error",
          message: err.response.data.error + "!"
        });
      });

      if (response) {
        this.setNotification({
          show: true,
          type: "success",
          message: "Trace rule added successfully!"
        });
      }
    },
  },
  async beforeMount() {

  }
};
</script>
<style lang="scss"></style>
