<template>
  <SearchText
      label="Search..."
      @search-updated="handelSearch"
      @search-clear="handelSearchClear"
  />
  <div
      v-for="(r,i) in rulesComputed"
      :key="i"
      :class="['schedule-item', { '-inactive': r.active === '0' }]"
  >
    <div class="row">
      <div class="col-7">
        <p class="text-size-16">
          <strong>Type</strong> : {{ deCamel(r.type) }}
        </p>
      </div>
      <div class="col-5">
        <input
            :id="`switch_${i}`"
            :checked="r.active === '1'"
            class="toggleSwitch"
            type="checkbox"
            @change="handleToggleChange(r.id)"
        /><label
          :for="`switch_${i}`"
          class="toggleLabel float-right"
      ></label>
      </div>
    </div>

    <div class="row schedule-item-inner d-flex flex-row align-items-start justify-content-lg-start">
      <div class="col-3">
        <span class="text-size-14 text-subdued"
        ><strong>ID</strong> : {{ r.id }}
        </span>
      </div>
      <div class="col-3">
        <span class="text-size-14"
        ><strong>Affects</strong> :<br/>
          {{ deCamel(r.parent) }}
        </span>
      </div>
      <div class="col-4">
        <RuleDisplay
            :rule="r"
        />
      </div>
    </div>

    <div class="control mt-4">
      <div class="link-text text-color-red" @click="handleDelete(r.id)">delete</div>
    </div>
  </div>
</template>
<script>

import {mapActions} from "vuex";
import SearchText from "@/components/search/SearchText";
import RuleDisplay from "@/components/trace-rule-manager/RuleDisplay";

export default {
  name: "RagRuleView",
  data: () => ({
    rules: [],
    searchedRules: null,
  }),
  components: {
    SearchText,
    RuleDisplay,
  },
  computed: {
    rulesComputed() {
      return this.searchedRules || this.rules
    }
  },
  methods: {
    ...mapActions([
      'fetchTraceRule',
      'deletedTraceRule',
      'searchTraceRules',
      'toggleTraceRuleActive',
    ]),
    async handleToggleChange(id) {
      await this.toggleTraceRuleActive({id}).catch(err =>
          console.log(err)
      );
      if (this.searchText !== "") {
        this.handelSearch(this.searchText);
      } else {
        this.fetchScheduleData();
      }
    },
    async handleDelete(id) {
      if (!confirm("Delete this email schedule?")) return;
      let result = await this.deletedTraceRule(id).catch(err =>
          console.log(err)
      );
      this.rules = result.data.data;
    },
    async handelSearch(search) {
      if (search === "") {
        this.searchedRules = null;
        return;
      }
      let response = await this.searchTraceRules({search}).catch(err => console.log(err));

      if (response.status === 200) {
        this.searchedRules = response.data.data;
      }
    },
    handelSearchClear() {
      this.searchedRules = null;
    },
    async fetchData() {
      let response = await this.fetchTraceRule().catch(err => console.log(err));

      if (response.status === 200) {
        this.rules = response.data.data;
      }
    }
  },
  async beforeMount() {
    await this.fetchData();
  }
}
</script>
<style lang="scss">
.schedule-item {
  border-radius: $borderRadius;
  padding: $spacingBase;
  background-color: $lightergrey;
  margin-bottom: calc($spacingBase / 2);
  width: 100%;

  &.-inactive {
    opacity: 0.3;
  }

  &-inner {
    border-radius: $borderRadius;
    background-color: white;
    padding: calc($spacingBase / 2);
    //height: 200px;

    div[class^="col-"] {
      margin-bottom: $spacingBase;
    }
  }

  .control {
  }
}
</style>