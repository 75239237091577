<template>
  <div class="container">
    <TitleHeader>Trace Rule Manager |
      <span class="text-color-darkgrey">{{ page }}</span></TitleHeader>
    <div class="row">
      <div class="col-3">
        <SubMenu>
          <SubMenuLink
              :icon-src="list"
              :page="page"
              link-name="Create rule"
              @navigate="handleNavigate"
          />
          <SubMenuLink
              :icon-src="clock"
              :page="page"
              link-name="View rules"
              @navigate="handleNavigate"
          />
        </SubMenu>
      </div>
      <div class="col-9">
        <div class="card mb-4">
          <div class="card-body text-left">
            <TraceRuleForm v-if="page === 'Create rule'"/>
            <RuleView v-if="page === 'View rules'"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SubMenu from "@/components/menu/SubMenu";
import SubMenuLink from "@/components/menu/SubMenuLink";
import TraceRuleForm from "@/components/trace-rule-manager/TraceRuleForm";
import RuleView from "@/components/trace-rule-manager/RuleView";
import list from "@fa/regular/list-alt.svg";
import clock from "@fa/regular/clock.svg";
import {mapActions, mapMutations} from "vuex";

export default {
  name: "RuleManager",
  components: {
    SubMenu,
    SubMenuLink,
    TraceRuleForm,
    RuleView
  },
  data: () => ({
    page: "Create rule",
    list,
    clock
  }),
  methods: {
    ...mapActions(['fetchProviderList']),
    ...mapMutations(['setLoading']),
    handleNavigate(page) {
      this.page = page;
    }
  },
  async beforeMount() {
    
  }
};
</script>
<style lang="scss"></style>